<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">New Region User</h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label>Region</label>
      <v-select class="user-input"
                :clearable="false"
                :options="regionMap"
                index="value"
                v-model="regionUser.region_id"
      ></v-select>
    </div>

    <template v-if="user === null">
      <div class="form-group text-left">
        <label>User</label>
        <v-select class="user-input"
                  :clearable="false"
                  :options="userMap"
                  index="value"
                  v-model="regionUser.user_id"
        ></v-select>
      </div>
    </template>

    <div class="form-group text-left">
      <label>Default?</label>
      <b-checkbox v-model="regionUser.default"></b-checkbox>
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'RegionUserIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createRegionUser" type="button" class="btn btn-success">Create Region User</button>
      </div>
    </div>

    <br>

  </div>
</template>

<script>
import DangerAlert from '@/common/components/danger-alert.vue';
import { RegionService } from '@/common/services/region.service';
import { UserService } from '@/common/services/user.service';
import { RegionUserService } from '@/common/services/region_user.service';

export default {
  name: 'RegionUserNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      regionUser: {
        user_id: null,
        region_id: null,
        default: true,
      },
      regions: [],
      regionMap: [],
      users: [],
      userMap: [],
      user: null,
      errors: [],
      userId: null,
    };
  },
  created() {
    this.userId = this.$route.params.userId;
    this.getUsers();
    this.getRegions();
    if (this.userId) {
      this.getUser();
    }
  },
  methods: {
    getUsers() {
      UserService.query().then((response) => {
        this.users = response.data;
      }).then(() => {
        for (let i = 0; i < this.users.length; i += 1) {
          const user = this.users[i];
          if (user) {
            const option = {};
            option.label = `${user.full_name} (${user.role})`;
            option.value = user.id;
            this.userMap.push(option);
          }
        }
      });
    },
    getUser() {
      UserService.get(this.userId).then((response) => {
        this.user = response.data;
      }).then(() => {
        this.regionUser.user_id = this.user.id;
      });
    },
    getRegions() {
      RegionService.query().then((response) => {
        this.regions = response.data;
      }).then(() => {
        for (let i = 0; i < this.regions.length; i += 1) {
          const region = this.regions[i];
          if (region) {
            const option = {};
            option.label = region.name;
            option.value = region.id;
            this.regionMap.push(option);
          }
        }
      });
    },
    createRegionUser() {
      RegionUserService.create(this.regionUser).then(({ data }) => {
        this.$router.push({ name: 'RegionUserShow', params: { id: data.id } }, () => {
          this.$notify({
            title: 'Created Region User',
            text: 'Successfully created Region User!',
            type: 'success',
            width: 350,
          });
        });
      }).catch((err) => {
        this.errors = err.response.data.error;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
